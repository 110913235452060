import React from 'react';
import PhotosList from './components/PhotosList';

function App() {
  return (
      <PhotosList />
  );
}

export default App;
